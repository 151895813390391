<template>
  <div class="supper">
    <div class="holder">
      <Header />
      <!-- <div v-if="loader" class="loader-div pt-3 pb-3">
        <i class="fa fa-spinner fa-spin fa-2x ml-3 f-12  btn-loader"> </i>
      </div> -->
      <div v-if="loader">
        <PageLoader></PageLoader>
      </div>
      <div v-if="!loader">
        <div v-if="articleOfTheDay" class="blog_banner">
          <div class="container atticle_wrap">
            <div class="article_title">
              <h6>Article of the day</h6>
              <h1 v-html="articleOfTheDay.title"></h1>
            </div>
            <div class="article_body">
              <p v-html="articleOfTheDay.introduction">...</p>
              <!-- <small>{{articleOfTheDay.created_at}}</small> -->
              <div class="readacticle">
                <button @click="moveTo(articleOfTheDay.code)" class="btn">
                  Read Article
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- <Pagination></Pagination> -->

        <!-- Featured article begind -->
        <div
          v-if="featureArticles.length > 0"
          class="container featured_holder"
        >
          <h6 class="featured_article_text">
            Featured Articles
          </h6>
          <div class="">
            <div class="row">
              <div
                class="col-md-6 col-xl-6"
                v-for="(featured, index) in featureArticles"
                :key="index"
              >
                <div class="featured_article_wrap">
                  <img v-if="featured.images.length> 0" :src="featured.images[0].imageurl" alt="" />
                  <h4 v-html="featured.title">...</h4>
                  <p v-html="featured.introduction">...</p>
                </div>
                <div class="read_main_article">
                  <button @click="moveTo(featured.code)" class="btn">
                    Read Article
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Featured article ends -->

        <!-- All Articles begins -->
        <div v-if="blogs.length > 0" class="container all_holder">
          <h6 class="all_article_text">
            All Articles
          </h6>
          <div class="">
            <div class="row">
              <!-- col-md-4 col-xl-4 -->
              <div
                class="col-md-6 col-lg-4 col-sm-12 col-lg-4 col-xl-4"
                v-for="(blog, index) in blogs"
                :key="index"
              >
                <div class="all_article_wrap">
                  {{ dot }}
                  <img v-if="blog.images.length > 0" :src="blog.images[0].imageurl" alt="" />
                  <h4 v-html="blog.title"></h4>
                  <p v-html="blog.introduction"></p>
                </div>
                <div class="read_main_article">
                  <button @click="moveTo(blog.code)" class="btn">
                    Read Article
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- All Articles ends -->

        <div class="load_more d-none">
          <button class="btn">Load more</button>
        </div>
      </div>
    </div>
    <FooterMain />
  </div>
</template>

<script>
import PageLoader from "./PageLoader.vue";
import FooterMain from "../views/FooterMain";
import Header from "../views/Header";
import _ from "lodash";
import { Service } from "../store/service";
import Pagination from "../components/Pagination.vue";
const Api = new Service();

export default {
  name: "Blog",
  components: {
    Header,
    FooterMain,
    PageLoader,
    Pagination
  },
  data() {
    return {
      loader: false,
      blogs: [],
      featureArticles: [],
      articleOfTheDay: {},
      code: "",
      dot: "...."
    };
  },
  mounted() {
    // this.fetchAllBlogPosts();
    // this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    this.fetchAllBlogPosts();
    // this.$store.commit("setPaginationUrl", `user/logged-equiptments`);
  },
  methods: {
    moveTo: function(id) {
      // this.code = event;
      // console.log('eVENT', event);
      // console.log('Main code', this.code);
      this.$router
        .push({
          path: `/blog/${id}`
        })
        .catch(() => {});
      // this.$router.push("/blog/${id}");
    },
    fetchAllBlogPosts: async function(pin) {
      this.loader = true;
      Api.pushPageURL(`user/logged-equiptments`);
      // this.$store.commit("setPaginationUrl", `user/logged-equiptments`);
      try {
        await Api.getRequestNoAuth(`fetchblogsarticles`)
          .then(res => {
            this.loader = false;
            // console.log("blogs", res);
            this.blogs = res.data.blogs;
            this.featureArticles = res.data.featured;
            this.articleOfTheDay = res.data.postoftheday;
          })
          .catch(err => {
            console.log(err);
            this.loader = false;
          });
      } finally {
        console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #000000;
$tertiary: #a2b0e3;
$whiteText: #ffffff;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.supper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.holder {
  flex-grow: 1;
  //    background: url("../assets/images/comingsoon.svg") no-repeat center center;
  // min-height: 100vh;
}
.loader-div {
  margin: auto;
  padding-top: 13rem !important;
}
.blog_banner {
  height: fit-content;
  min-height: 700px;
  background: url("../assets/images/bloooog.svg") no-repeat center center,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.5) 32.73%,
      rgba(0, 0, 0, 0.165) 100%
    );
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  text-align: left;
}

.atticle_wrap {
  // margin: 15px;
}
.article_title {
  padding-top: 10rem;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 100;
    font-size: 25px;
    line-height: 138.2%;
    color: $secondary;
    border-bottom: 2px solid $primary;
    max-width: 20%;
    width: 100%;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
  }
  h1 {
    font-family: Gotham-bold;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 131.2%;
    color: $secondary;
    padding-bottom: 0.5rem;
    max-width: 66%;
    width: 100%;
  }
}

.article_body {
  max-width: 45%;
  width: 100%;
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 190.7%;
    color: $secondary;
    max-height: 74px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.readacticle {
  button {
    background-color: $primary;
    color: #ffffff;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 190.7%;
  }
}

// Featured article
.featured_holder {
  background-color: $whiteText;
  padding-top: 5rem;
}
.featured_article_text {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 190.7%;
  color: $secondary;
  text-align: left;
  border-bottom: 2px solid $primary;
  max-width: 17%;
  width: 100%;
  padding-bottom: 0.5rem;
}
.featured_article_wrap {
  padding-top: 3rem;
  img {
    width: 100%;
    height: 100%;
    max-height: 318px;
    max-width: 609px;
    border-radius: 4px;
  }
  h4 {
    font-family: Gotham-bold;
    font-style: normal;
    font-weight: 500;
    font-size: 35px;
    text-align: left;
    line-height: 131.2%;
    color: $secondary;
    // white-space: nowrap;
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 190.7%;
    color: $secondary;
    text-align: left;
    max-height: 74px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.read_main_article {
  text-align: left;
  button {
    color: $primary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 190.7%;
    text-align: left;
    padding: 0;
    box-shadow: none;
  }
}
.read_main_article button:hover {
  color: #3653b3;
  font-size: 15px;
}
// Featured article

// All article begins
.all_holder {
  background-color: $whiteText;
  padding-top: 5rem;
}
.all_article_text {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 190.7%;
  color: $secondary;
  text-align: left;
  border-bottom: 2px solid $primary;
  max-width: 11%;
  width: 100%;
  padding-bottom: 0.5rem;
}
.all_article_wrap {
  padding-top: 2rem;
  img {
    width: 100%;
    height: 100%;
    max-height: 236.54px;
    min-height: 236.54px;
    max-width: 398.06px;
    border-radius: 4px;
  }
  h4 {
    font-family: Gotham-bold;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    text-align: left;
    line-height: 131.2%;
    color: $secondary;
    padding-top: 10px;
    // max-height: 3.5rem;
    max-height: 40px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 15px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    p {
      text-indent: 0in !important;
    }
    .MsoListParagraph {
      text-indent: 0in !important;
    }
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 190.7%;
    color: $secondary;
    text-align: left;
    max-height: 74px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.read_main_article {
  text-align: left;
  button {
    color: $primary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 190.7%;
    text-align: left;
    padding: 0;
  }
}
// All article ends

.load_more {
  margin: 6rem 10px 4rem 10px;
  button {
    padding-left: 40px;
    padding-right: 40px;
    background-color: $whiteText;
    color: $primary;
    border: 0.5px solid $primary;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 131.2%;
  }
}

::-webkit-scrollbar {
  display: none;
}

@media screen and (min-device-width: 300px) and (max-device-width: 640px) and (-webkit-min-device-pixel-ratio: 1) {
  // @media (min-width: 300px) {
  .article_title {
    padding-top: 9rem;
    h6 {
      max-width: 100%;
      border-bottom: none;
    }
    h1 {
      font-size: 20px;
      max-width: 100%;
    }
  }
  .article_body {
    max-width: 100%;
  }
  .featured_holder {
    padding-top: 2rem;
  }
  .featured_article_text {
    max-width: 100%;
    border-bottom: none;
  }
  .featured_article_wrap {
    padding-top: 0;
    img {
      min-height: 171.54px;
    }
  }
  .featured_article_wrap {
    h4 {
      font-size: 22px;
      padding-top: 10px;
    }
    p {
      margin-bottom: 5px;
    }
  }
  .all_article_text {
    max-width: 100%;
    border-bottom: 0;
  }
  .all_holder {
    padding-top: 0;
  }
  .all_article_wrap {
    padding-top: 0;
    img {
      // max-height: 171.54px;
      max-width: 100%;
    }
    h4 {
      padding-top: 10px;
    }
    p {
      margin-bottom: 5px;
    }
  }
  .read_main_article {
    margin-bottom: 15px;
  }
  .load_more {
    margin: 1rem 10px 6rem 10px;
  }
}

/* Tablet */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .article_title {
    h6 {
      max-width: 32%;
    }
    h1 {
      font-size: 28px;
    }
  }
  .featured_article_text {
    max-width: 28%;
  }
  .article_body {
    max-width: 100%;
  }
  .featured_article_wrap {
    h4 {
      font-size: 22px;
      padding-top: 10px;
    }
  }
  .all_article_wrap {
    h4 {
      font-size: 17px;
    }
  }
  .all_article_text {
    max-width: 18%;
  }
}
</style>
